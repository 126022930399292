import React, { Component } from 'react'
import Link from '../utils/link'

class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <nav>
            <ul>
              <li>
                <Link to='/'>
                  Industria Braybrook
                </Link>
              </li>
              <li>
                <Link to='/disclaimer'>
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link to='/privacy-policy'>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <span>Website by</span> <Link to='https://www.studiohartwood.com.au/'>
                  Studio Hartwood
                </Link>
              </li>
              <li>
                <span>© { new Date().getFullYear() } </span>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    )
  }
}

export default Footer
